import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {

	onb1 = 1;
	onb2 = 0;
	onb3 = 0;

  constructor(private router: Router) { }

  ngOnInit() {}

  next1(){
  	this.onb1 = 0;
  	this.onb2 = 1;
  }
  next2(){
  	this.onb2 = 0;
  	this.onb3 = 1;
  }
  start(){
  	this.onb3 = 0;
  	this.router.navigate(['/auth']); 
  }

}
