import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';

import { Arrondissement } from '../models/arrondissement.model';

export type EntityResponseType = HttpResponse<Arrondissement>;

@Injectable()
export class ArrondissementsService {

    private resourceUrl =  this.global_variable.baseUrl + "/arrondissements";
    private resourceUrl2 =  this.global_variable.baseUrl + "/arrondissements_latest";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(arrondissement: Arrondissement): Observable<EntityResponseType> {
        const copy = this.convert(arrondissement);
        return this.http.post<Arrondissement>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(arrondissement: Arrondissement): Observable<EntityResponseType> {
        const copy = this.convert(arrondissement);
        return this.http.put<Arrondissement>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<Arrondissement>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findByName(name: string): Observable<EntityResponseType> {
        return this.http.get<Arrondissement>(`${this.resourceUrl}/name/${name}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    get_latest(): Observable<EntityResponseType> {
        return this.http.get<Arrondissement>(`${this.resourceUrl2}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<Arrondissement[]>> {
        return this.http.get<Arrondissement[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<Arrondissement[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Arrondissement = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<Arrondissement[]>): HttpResponse<Arrondissement[]> {
        const jsonResponse: Arrondissement[] = res.body;
        const body: Arrondissement[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to Arrondissement.*/
     
    private convertItemFromServer(arrondissement: Arrondissement): Arrondissement {
        const copy: Arrondissement = Object.assign({}, arrondissement);
        return copy;
    }

    
    /* Convert a Arrondissement to a JSON which can be sent to the server.*/
         private convert(arrondissement: Arrondissement): Arrondissement {
        const copy: Arrondissement = Object.assign({}, arrondissement);
        return copy;
    }
}



