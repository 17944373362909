import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map } from 'rxjs/operators';

import { Superficie } from '../models/superficie.model';

export type EntityResponseType = HttpResponse<Superficie>;

@Injectable()
export class SuperficieService {

    private resourceUrl =  this.global_variable.baseUrl + "/superficies";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(superficie: Superficie): Observable<EntityResponseType> {
        const copy = this.convert(superficie);
        return this.http.post<Superficie>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(superficie: Superficie): Observable<EntityResponseType> {
        const copy = this.convert(superficie);
        return this.http.put<Superficie>(`${this.resourceUrl}/${superficie.id_s}`, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<Superficie>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<Superficie[]>> {
        return this.http.get<Superficie[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<Superficie[]>) => this.convertArrayResponse(res)));
    }

    // delete(id: number): Observable<HttpResponse<any>> {
    //     return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    // }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Superficie = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<Superficie[]>): HttpResponse<Superficie[]> {
        const jsonResponse: Superficie[] = res.body;
        const body: Superficie[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

    //  /*Convert a returned JSON object to Superficie.*/
     
    private convertItemFromServer(superficie: Superficie): Superficie {
        const copy: Superficie = Object.assign({}, superficie);
        return copy;
    }

    
    /* Convert a Superficie to a JSON which can be sent to the server.*/
         private convert(superficie: Superficie): Superficie {
        const copy: Superficie = Object.assign({}, superficie);
        return copy;
    }
}



