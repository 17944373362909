import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './share/services/auth-guard.service';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { OnboardingComponent } from './onboarding/onboarding.component';



const routes: Routes = [
  {
    path: 'pages',
      canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  { 
    path: 'pages-u',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages-u/pages-u.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'pages-r',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages-r/pages-r.module')
      .then(m => m.PagesRModule),
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
  },
  {
    path: '',

    loadChildren: './auth/auth.module#AuthModule',
  },

  
  //{ path: '', component: OnboardingComponent},

  { path: '**', redirectTo: 'auth' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
