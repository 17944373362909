import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';

import { Face } from '../models/face.model';

export type EntityResponseType = HttpResponse<Face>;

@Injectable()
export class FacesService {

    private resourceUrl =  this.global_variable.baseUrl + "/faces";
    private resourceUrl2 =  this.global_variable.baseUrl + "/faces_latest";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(face: Face): Observable<EntityResponseType> {
        const copy = this.convert(face);
        return this.http.post<Face>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(face: Face): Observable<EntityResponseType> {
        const copy = this.convert(face);
        return this.http.put<Face>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<Face>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findByName(name: string): Observable<EntityResponseType> {
        return this.http.get<Face>(`${this.resourceUrl}/name/${name}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    get_latest(): Observable<EntityResponseType> {
        return this.http.get<Face>(`${this.resourceUrl2}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    suivante_a_valider(id_face: string): Observable<HttpResponse<Face[]>> {
        return this.http.get<Face[]>(`${this.resourceUrl}/suivante_a_valider/${id_face}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Face[]>) => this.convertArrayResponse(res)));
    }
    suivante(id_face: string): Observable<HttpResponse<Face[]>> {
        return this.http.get<Face[]>(`${this.resourceUrl}/suivante/${id_face}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Face[]>) => this.convertArrayResponse(res)));
    }
    precedente(id_face: string): Observable<HttpResponse<Face[]>> {
        return this.http.get<Face[]>(`${this.resourceUrl}/precedente/${id_face}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Face[]>) => this.convertArrayResponse(res)));
    }
    findFacesForOrientation(condittion: string): Observable<HttpResponse<Face[]>> {
        return this.http.get<Face[]>(`${this.resourceUrl}/faces/${condittion}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Face[]>) => this.convertArrayResponse(res)));
    }

    findFaceForOrientation(id_f: number): Observable<HttpResponse<Face[]>> {
        return this.http.get<Face[]>(`${this.resourceUrl}/face/${id_f}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Face[]>) => this.convertArrayResponse(res)));
    }

    findByIdPanneau(id: number): Observable<HttpResponse<Face[]>> {
        console.log( id )
        return this.http.get<Face[]>(`${this.resourceUrl}/panneau/${id}`, { observe: 'response' })
            .pipe(map((res: HttpResponse<Face[]>) => this.convertArrayResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<Face[]>> {
        return this.http.get<Face[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<Face[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Face = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<Face[]>): HttpResponse<Face[]> {
        const jsonResponse: Face[] = res.body;
        const body: Face[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to Face.*/
     
    private convertItemFromServer(face: Face): Face {
        const copy: Face = Object.assign({}, face);
        return copy;
    }

    
    /* Convert a Face to a JSON which can be sent to the server.*/
         private convert(face: Face): Face {
        const copy: Face = Object.assign({}, face);
        return copy;
    }
}



