export class Global_variable {
  //baseUrl:string = "https://aspi-api.herokuapp.com";// heruko api

  baseUrl: string = "https://api.aspi-label.com"; // planethoster api

  //baseUrl:string = "http://localhost:5000";// local api

  user_connected;

  isAuth;

  panneau_to_add = {
    date_debut: "",
    date_fin: "",
    duree: "",
    sql_query: "",
    liste_faces: [],
  };

  camapagne_to_add = {
    id_c: "",
    nom: "",
    produit: "",
    titre: "",
    fk_added_user_id: 0,
    fk_annonceur_id: 0,
    fk_added_user_regie_id: 0,
    date_declaration: "",
    nombre_panneau: 0,
  };

  declaration_data = [];

}
