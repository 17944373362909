import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map } from 'rxjs/operators';

import { Regie } from '../models/regie.model';

export type EntityResponseType = HttpResponse<Regie>;

@Injectable()
export class RegiesService {

    private resourceUrl =  this.global_variable.baseUrl + "/regies";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(regie: Regie): Observable<EntityResponseType> {
        const copy = this.convert(regie);
        return this.http.post<Regie>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(regie: Regie): Observable<EntityResponseType> {
        const copy = this.convert(regie);
        return this.http.put<Regie>(`${this.resourceUrl}/${regie.id_r}`, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<Regie>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findByName(name: string): Observable<EntityResponseType> {
        return this.http.get<Regie>(`${this.resourceUrl}/name/${name}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<Regie[]>> {
        return this.http.get<Regie[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<Regie[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Regie = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<Regie[]>): HttpResponse<Regie[]> {
        const jsonResponse: Regie[] = res.body;
        const body: Regie[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to Regie.*/
     
    private convertItemFromServer(regie: Regie): Regie {
        const copy: Regie = Object.assign({}, regie);
        return copy;
    }

    
    /* Convert a Regie to a JSON which can be sent to the server.*/
         private convert(regie: Regie): Regie {
        const copy: Regie = Object.assign({}, regie);
        return copy;
    }
}



