import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';

import { Lieu } from '../models/lieu.model';

export type EntityResponseType = HttpResponse<Lieu>;

@Injectable()
export class LieuxService {

    private resourceUrl =  this.global_variable.baseUrl + "/lieux";
    private resourceUrl2 =  this.global_variable.baseUrl + "/lieux_latest";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(lieu: Lieu): Observable<EntityResponseType> {
        const copy = this.convert(lieu);
        return this.http.post<Lieu>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(lieu: Lieu): Observable<EntityResponseType> {
        const copy = this.convert(lieu);
        return this.http.put<Lieu>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<Lieu>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    get_lieu_quatier_arrondissement( id: number): Observable<EntityResponseType> {
        return this.http.get<Lieu>(`${this.resourceUrl}/all/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findByName(name: string): Observable<EntityResponseType> {
        return this.http.get<Lieu>(`${this.resourceUrl}/name/${name}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    get_latest(): Observable<EntityResponseType> {
        return this.http.get<Lieu>(`${this.resourceUrl2}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<Lieu[]>> {
        return this.http.get<Lieu[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<Lieu[]>) => this.convertArrayResponse(res)));
    }
    queryByQuartier_id(id: number): Observable<HttpResponse<Lieu[]>> {
        return this.http.get<Lieu[]>(`${this.resourceUrl}/quartier/${id}`, { observe: 'response' })
            .pipe(map((res: HttpResponse<Lieu[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Lieu = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<Lieu[]>): HttpResponse<Lieu[]> {
        const jsonResponse: Lieu[] = res.body;
        const body: Lieu[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to Lieu.*/
     
    private convertItemFromServer(lieu: Lieu): Lieu {
        const copy: Lieu = Object.assign({}, lieu);
        return copy;
    }

    
    /* Convert a Lieu to a JSON which can be sent to the server.*/
         private convert(lieu: Lieu): Lieu {
        const copy: Lieu = Object.assign({}, lieu);
        return copy;
    }
}



