import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';

import { Annonceur } from '../models/annonceur.model';

export type EntityResponseType = HttpResponse<Annonceur>;

@Injectable()
export class AnnonceursService {

    private resourceUrl =  this.global_variable.baseUrl + "/annonceurs";
    private resourceUrl2 =  this.global_variable.baseUrl + "/annonceurs_regie";
    private resourceUrl3 =  this.global_variable.baseUrl + "/annonceurs_latest";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(annonceur: Annonceur): Observable<EntityResponseType> {
        const copy = this.convert(annonceur);
        return this.http.post<Annonceur>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(id: number, nom: string): Observable<EntityResponseType> {
        return this.http.put<Annonceur>(`${this.resourceUrl}/${id}`, {nom:nom}, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<Annonceur>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    get_annonceurs_regie(id_regie: number): Observable<HttpResponse<Annonceur[]>> {
        return this.http.get<Annonceur[]>(`${this.resourceUrl2}/${id_regie}`, { observe: 'response' })
            .pipe(map((res: HttpResponse<Annonceur[]>) => this.convertArrayResponse(res)));
    }

    get_latest(): Observable<HttpResponse<Annonceur[]>> {
        return this.http.get<Annonceur[]>(`${this.resourceUrl3}`, { observe: 'response' })
            .pipe(map((res: HttpResponse<Annonceur[]>) => this.convertArrayResponse(res)));
    }

    findByName(name: string): Observable<EntityResponseType> {
        return this.http.get<Annonceur>(`${this.resourceUrl}/name/${name}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<Annonceur[]>> {
        return this.http.get<Annonceur[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<Annonceur[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Annonceur = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<Annonceur[]>): HttpResponse<Annonceur[]> {
        const jsonResponse: Annonceur[] = res.body;
        const body: Annonceur[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to Annonceur.*/
     
    private convertItemFromServer(annonceur: Annonceur): Annonceur {
        const copy: Annonceur = Object.assign({}, annonceur);
        return copy;
    }

    
    /* Convert a Annonceur to a JSON which can be sent to the server.*/
         private convert(annonceur: Annonceur): Annonceur {
        const copy: Annonceur = Object.assign({}, annonceur);
        return copy;
    }
}



