import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable()
export class ImagesService {

    /*
    anienne version de gestion des images
    private resourceUrl =  this.global_variable.baseUrl + "/images";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

        send_to_server(lien, data) {

            let result = { contenu : data }
            
            return this.http.post<any>(`${this.resourceUrl}/${lien}`, result , { observe: 'response'});
        }

        get_from_server( name: string)
        {
            return this.http.get<any>(`${this.resourceUrl}/${name}` , { observe: 'response'});
        }
    */

   constructor(private afStorage: AngularFireStorage) { }
 
   
   uploadToStorage(imgName, imgData) {
     return this.afStorage.ref(imgName).putString(imgData, 'data_url').then(function(snapshot) {
       console.log('Uploaded a data_url string!');
     });
   }
   
   getMediaPhoto(mediaId) {
     return this.afStorage.ref(`mediaPhotos/${mediaId}`).getDownloadURL();
   }
    
}