import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';



@Injectable()
export class SqliteGetterService {

    private resourceUrl =  this.global_variable.baseUrl + "/sqlite";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }


    get_support(): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(`${this.resourceUrl}/support`, { observe: 'response' })
            .pipe(map((res: HttpResponse<any[]>) => this.convertArrayResponse(res)));
    }

    get_face(): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(`${this.resourceUrl}/face`, { observe: 'response' })
            .pipe(map((res: HttpResponse<any[]>) => this.convertArrayResponse(res)));
    }


    private convertArrayResponse(res: HttpResponse<any[]>): HttpResponse<any[]> {
        const jsonResponse: any[] = res.body;
        const body: any[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to Annonceur.*/
     
    private convertItemFromServer(annonceur: any): any {
        const copy: any = Object.assign({}, annonceur);
        return copy;
    }

}



