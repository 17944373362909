import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';

import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { User } from '../models/users.model';

export type EntityResponseType = HttpResponse<User>;

@Injectable()
export class UsersService {

    private resourceUrl =  this.global_variable.baseUrl + "/users";
    private resourceUrl2 =  this.global_variable.baseUrl + "/users_latest";
    private cni_ressouce = this.global_variable.baseUrl + "/users_cni";
    private user_regie = this.global_variable.baseUrl + "/user_regie";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(user: User): Observable<EntityResponseType> {
        const copy = this.convert(user);
        return this.http.post<User>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(user: User): Observable<EntityResponseType> {
        const copy = this.convert(user);
        return this.http.put<User>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    find(email: string, password: string): Observable<EntityResponseType> {
            return this.http.get<User>(`${this.resourceUrl}/${email}/${password}`, { observe: 'response'})
                .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<User>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    get_latest(): Observable<EntityResponseType> {
        return this.http.get<User>(`${this.resourceUrl2}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findByCNI(cni: string): Observable<EntityResponseType> {
        return this.http.get<User>(`${this.cni_ressouce}/${cni}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findByName(name: string): Observable<EntityResponseType> {
        return this.http.get<User>(`${this.resourceUrl}/name/${name}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    get_users_regie(id: number): Observable<HttpResponse<User[]>> {
        return this.http.get<User[]>(`${this.user_regie}/${id}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<User[]>) => this.convertArrayResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<User[]>> {
        return this.http.get<User[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<User[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }

    //getApiUrl : string = "https://jsonplaceholder.typicode.com/posts";

    getApiUrl : string = "https://aspi-api.herukoapp.com/users";



    getUsers(req?: any): Observable<HttpResponse<User[]>> {
        return this.http.get<User[]>(this.getApiUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<User[]>) => this.convertArrayResponse(res)));
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: User = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<User[]>): HttpResponse<User[]> {
        const jsonResponse: User[] = res.body;
        const body: User[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to User.*/
     
    private convertItemFromServer(user: User): User {
        const copy: User = Object.assign({}, user);
        return copy;
    }

    
    /* Convert a User to a JSON which can be sent to the server.*/
         private convert(user: User): User {
        const copy: User = Object.assign({}, user);
        return copy;
    }
}



