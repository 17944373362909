import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';

import { Quartier } from '../models/quartier.model';

export type EntityResponseType = HttpResponse<Quartier>;

@Injectable()
export class QuartiersService {

    private resourceUrl =  this.global_variable.baseUrl + "/quartiers";
    private resourceUrl2 =  this.global_variable.baseUrl + "/quartiers_latest";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(quartier: Quartier): Observable<EntityResponseType> {
        const copy = this.convert(quartier);
        return this.http.post<Quartier>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(quartier: Quartier): Observable<EntityResponseType> {
        const copy = this.convert(quartier);
        return this.http.put<Quartier>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<Quartier>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    get_latest(): Observable<EntityResponseType> {
        return this.http.get<Quartier>(`${this.resourceUrl2}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findByName(name: string): Observable<EntityResponseType> {
        return this.http.get<Quartier>(`${this.resourceUrl}/name/${name}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<Quartier[]>> {
        return this.http.get<Quartier[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<Quartier[]>) => this.convertArrayResponse(res)));
    }
    queryByArrondissements_id(id: number): Observable<HttpResponse<Quartier[]>> {
        return this.http.get<Quartier[]>(`${this.resourceUrl}/arrondissement/${id}`, { observe: 'response' })
            .pipe(map((res: HttpResponse<Quartier[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Quartier = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<Quartier[]>): HttpResponse<Quartier[]> {
        const jsonResponse: Quartier[] = res.body;
        const body: Quartier[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to Quartier.*/
     
    private convertItemFromServer(quartier: Quartier): Quartier {
        const copy: Quartier = Object.assign({}, quartier);
        return copy;
    }

    
    /* Convert a Quartier to a JSON which can be sent to the server.*/
         private convert(quartier: Quartier): Quartier {
        const copy: Quartier = Object.assign({}, quartier);
        return copy;
    }
}



