import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';

import { Campagne_face } from '../models/campagne_face.model';

export type EntityResponseType = HttpResponse<Campagne_face>;

@Injectable()
export class Campagne_facesService {

    private resourceUrl =  this.global_variable.baseUrl + "/campagne_face";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(campagne_face: Campagne_face): Observable<EntityResponseType> {
        const copy = this.convert(campagne_face);
        return this.http.post<Campagne_face>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    create_many(query: string): Observable<EntityResponseType> {
        return this.http.post(`${this.resourceUrl}/query`, {query:query}, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(campagne_face: Campagne_face): Observable<EntityResponseType> {
        const copy = this.convert(campagne_face);
        return this.http.put<Campagne_face>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<Campagne_face>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }
    


    query(req?: any): Observable<HttpResponse<Campagne_face[]>> {
        return this.http.get<Campagne_face[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<Campagne_face[]>) => this.convertArrayResponse(res)));
    }
    queryByIdcampagne(id: number): Observable<HttpResponse<Campagne_face[]>> {
        return this.http.get<Campagne_face[]>(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res: HttpResponse<Campagne_face[]>) => this.convertArrayResponse(res)));
    }

    findByIdFace(id: number): Observable<EntityResponseType> {
        return this.http.get<Campagne_face>(`${this.resourceUrl}/face/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    delete(id_campagne:number,id_face:number,date_debut:string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id_campagne}/${id_face}/${date_debut}`, { observe: 'response'});
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Campagne_face = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<Campagne_face[]>): HttpResponse<Campagne_face[]> {
        const jsonResponse: Campagne_face[] = res.body;
        const body: Campagne_face[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to Campagne_face.*/
     
    private convertItemFromServer(campagne_face: Campagne_face): Campagne_face {
        const copy: Campagne_face = Object.assign({}, campagne_face);
        return copy;
    }

    
    /* Convert a Campagne_face to a JSON which can be sent to the server.*/
         private convert(campagne_face: Campagne_face): Campagne_face {
        const copy: Campagne_face = Object.assign({}, campagne_face);
        return copy;
    }
}



