import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';

import { Pige } from '../models/pige.model';

export type EntityResponseType = HttpResponse<Pige>;

@Injectable()
export class PigesService {

    private resourceUrl =  this.global_variable.baseUrl + "/piges";
    private resourceUrl2 =  this.global_variable.baseUrl + "/filtres_piges";

    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(pige: Pige): Observable<EntityResponseType> {
        const copy = this.convert(pige);
        return this.http.post<Pige>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<Pige>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }
    select_one(id_face, id_user, date): Observable<HttpResponse<Pige[]>> {
        return this.http.get<Pige[]>(`${this.resourceUrl}/${id_face}/${id_user}/${date}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }
    select_next_valider(date): Observable<HttpResponse<Pige[]>> {
        return this.http.get<Pige[]>(`${this.global_variable.baseUrl}/select_next_pige_to_validate/${date}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }
    select_all_pige(id_f): Observable<HttpResponse<Pige[]>> {
        return this.http.get<Pige[]>(`${this.global_variable.baseUrl}/select_all_pige_face/${id_f}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }

    select_previous_pige(date): Observable<HttpResponse<Pige[]>> {
        return this.http.get<Pige[]>(`${this.global_variable.baseUrl}/select_previous_pige/${date}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }
    select_next_pige(date): Observable<HttpResponse<Pige[]>> {
    return this.http.get<Pige[]>(`${this.global_variable.baseUrl}/select_next_pige/${date}`, { observe: 'response'})
        .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }
    select_by_date(id_face, date): Observable<HttpResponse<Pige[]>> {
        return this.http.get<Pige[]>(`${this.resourceUrl}/${id_face}/${date}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }
    //Select the latest pige on a specific face
    select_last_pige_face(id_f): Observable<HttpResponse<Pige[]>> {
        return this.http.get<Pige[]>(`${this.global_variable.baseUrl}/select_last_pige_face/${id_f}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }
    //Select a pige of a specifique face that is directly before the given date
    select_previous_pige_face(id_face, date): Observable<HttpResponse<Pige[]>> {
        return this.http.get<Pige[]>(`${this.global_variable.baseUrl}/select_previous_pige_face/${id_face}/${date}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }
    //Select a pige of a specifique face that is directly after the given date
    select_next_pige_face(id_face, date): Observable<HttpResponse<Pige[]>> {
    return this.http.get<Pige[]>(`${this.global_variable.baseUrl}/select_next_pige_face/${id_face}/${date}/`, { observe: 'response'})
        .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }


    query(req?: any): Observable<HttpResponse<Pige[]>> {
        return this.http.get<Pige[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }
    
    filtres(condittions): Observable<HttpResponse<Pige[]>> {
        return this.http.get<Pige[]>(`${this.resourceUrl2}/${condittions}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }
    query_by_user(id): Observable<HttpResponse<Pige[]>> {
        return this.http.get<Pige[]>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Pige[]>) => this.convertArrayResponse(res)));
    }
    update(pige: Pige): Observable<EntityResponseType> {
        const copy = this.convert(pige);
        return this.http.put<Pige>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    delete(id_face, id_user, date): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id_face}/${id_user}/${date}`, { observe: 'response'});
    }

    

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Pige = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<Pige[]>): HttpResponse<Pige[]> {
        const jsonResponse: Pige[] = res.body;
        const body: Pige[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to Piges.*/
     
    private convertItemFromServer(pige: Pige): Pige {
        const copy: Pige = Object.assign({}, pige);
        return copy;
    }

    
    /* Convert a Pige to a JSON which can be sent to the server.*/
         private convert(pige: Pige): Pige {
        const copy: Pige = Object.assign({}, pige);
        return copy;
    }
}



