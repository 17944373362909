import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Global_variable } from '../global_variables';
import { map, catchError } from 'rxjs/operators';

import { Campagne } from '../models/campagne.model';

export type EntityResponseType = HttpResponse<Campagne>;

@Injectable()
export class CampagnesService {

    private resourceUrl =  this.global_variable.baseUrl + "/campagnes";


    constructor(private http: HttpClient, private global_variable: Global_variable) { }

    create(campagne: Campagne): Observable<EntityResponseType> {
        const copy = this.convert(campagne);
        return this.http.post<Campagne>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    update(campagne: Campagne): Observable<EntityResponseType> {
        const copy = this.convert(campagne);
        return this.http.put<Campagne>(this.resourceUrl, copy, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    findById(id: number): Observable<EntityResponseType> {
        return this.http.get<Campagne>(`${this.resourceUrl}/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }
    
    select_campagne_if_exist(id_face, debut, fin ): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(`${this.global_variable.baseUrl}/select_campagne_if_exist/${id_face}/${debut}/${fin}`, { observe: 'response' })
            .pipe(map((res: HttpResponse<any[]>) => this.convertArrayResponse(res)));
    }

    select_campagne_en_cours(id_face, date ): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(`${this.global_variable.baseUrl}/select_campagne_en_cours/${id_face}/${date}`, { observe: 'response' })
            .pipe(map((res: HttpResponse<any[]>) => this.convertArrayResponse(res)));
    }

    findByName(name: string): Observable<EntityResponseType> {
        return this.http.get<Campagne>(`${this.resourceUrl}/name/${name}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    query(req?: any): Observable<HttpResponse<Campagne[]>> {
        return this.http.get<Campagne[]>(this.resourceUrl, { observe: 'response' })
            .pipe(map((res: HttpResponse<Campagne[]>) => this.convertArrayResponse(res)));
    }
    get_campagnes_regie(id_regie: number): Observable<HttpResponse<Campagne[]>> {
        return this.http.get<Campagne[]>(`${this.resourceUrl}/regie/${id_regie}`, { observe: 'response' })
            .pipe(map((res: HttpResponse<Campagne[]>) => this.convertArrayResponse(res)));
    }

    filtres_regie(condittions,id_regie): Observable<HttpResponse<Campagne[]>> {
        return this.http.get<Campagne[]>(`${this.resourceUrl}/filtre_regie/${id_regie}/${condittions}`, { observe: 'response'})
            .pipe(map((res: HttpResponse<Campagne[]>) => this.convertArrayResponse(res)));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response'});
    }
    getLastInsertId(id: number): Observable<EntityResponseType> {
        return this.http.get<Campagne>(`${this.resourceUrl}/user/${id}`, { observe: 'response'})
            .pipe(map((res: EntityResponseType) => this.convertResponse(res)));
    }

    private convertResponse(res: EntityResponseType): EntityResponseType {
        const body: Campagne = this.convertItemFromServer(res.body);
        return res.clone({body});
    }

    private convertArrayResponse(res: HttpResponse<Campagne[]>): HttpResponse<Campagne[]> {
        const jsonResponse: Campagne[] = res.body;
        const body: Campagne[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            body.push(this.convertItemFromServer(jsonResponse[i]));
        }
        return res.clone({body});
    }

     /*Convert a returned JSON object to Campagne.*/
     
    private convertItemFromServer(campagne: Campagne): Campagne {
        const copy: Campagne = Object.assign({}, campagne);
        return copy;
    }

    
    /* Convert a Campagne to a JSON which can be sent to the server.*/
         private convert(campagne: Campagne): Campagne {
        const copy: Campagne = Object.assign({}, campagne);
        return copy;
    }
}



