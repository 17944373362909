import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { UsersService } from "./share/services/users.service";
import { Global_variable } from "./share/global_variables";
import { ArrondissementsService } from "./share/services/arrondissements.service";
import { QuartiersService } from "./share/services/quartiers.service";
import { LieuxService } from "./share/services/lieux.service";
import { PanneauxService } from "./share/services/panneaux.service";
import { RegiesService } from "./share/services/regies.service";
import { SuperficieService } from "./share/services/superficies.service";
import { CampagnesService } from "./share/services/campagnes.service";
import { AnnonceursService } from "./share/services/annonceurs.service";
import { PigesService } from "./share/services/piges.service";
import { FacesService } from "./share/services/faces.service";
import { Campagne_facesService } from "./share/services/campagnes_face.service";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { Camera } from "@ionic-native/camera/ngx";
import { ImagesService } from "./share/services/images.service";
import { AuthGuard } from "./share/services/auth-guard.service";
import { SQLite } from "@ionic-native/sqlite/ngx";
import { FilePath } from "@ionic-native/file-path/ngx";
import { Network } from "@ionic-native/network/ngx";
import { SqliteGetterService } from "./share/services/sqlite.getter.service";
import { File } from "@ionic-native/file/ngx";

import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database-deprecated";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreModule,
} from "@angular/fire/firestore"; // << Note AngularFirestoreModule !!!
import { environment } from "../environments/environment";
import { Device } from "@ionic-native/device/ngx";
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AppComponent, OnboardingComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    // NgbModule,
    // NgbModule.forRoot()
  ],
  providers: [
    UsersService,
    Global_variable,
    ArrondissementsService,
    QuartiersService,
    LieuxService,
    PanneauxService,
    RegiesService,
    SuperficieService,
    CampagnesService,
    AnnonceursService,
    PigesService,
    FacesService,
    Campagne_facesService,
    Camera,
    ImagesService,
    AngularFirestore,
    AuthGuard,
    SQLite,
    FilePath,
    Network,
    SqliteGetterService,
    File,
    Device,
    // NgbModal
    //NgbActiveModal
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
